<template>
  <div class="part-component timeline boxslider">
    <div
      class="timeline-slider-wrap box-slider-wrap"
      :class="currentSlideClass"
    >
      <div class="box-slider content-slider">
        <vue-glide
          :options="options"
          :type="'slider'"
          v-model="activeSlide"
          v-on:glide:mount-after="sliderMounted"
          v-on:glide:run-before="textExpanded = null"
        >
          <vue-glide-slide v-for="(item, i) in data.slides" :key="i">
            <div
              :id="item.id"
              class="slide-content layout-row layout-align-center-start"
            >
              <div
                class="slide-text-wrap layout-column layout-align-center-end"
              >
                <h2
                  class="
                    slide-title
                    layout-align-end-end layout-column
                    italic
                    grey2
                    parallax-flex parallax-item
                  "
                  data-depth="2.2"
                >
                  {{ item.title }}
                  <template v-if="item.subtitle2">
                    <span>{{ item.subtitle2 }}</span>
                  </template>
                  <template v-if="item.subtitle">
                    <span>{{ item.subtitle }}</span>
                  </template>
                </h2>
                <p
                  class="slide-text text-right parallax-relative parallax-item"
                  data-depth="1.7"
                >
                  {{ item.text }}
                  <br />
                </p>
                <span
                  class="readmore din parallax-relative parallax-item"
                  data-depth="1.2"
                  @click="showModal(item.modal)"
                  >Read more</span
                >
                <div
                  class="line line-blue parallax-item"
                  data-depth="0.8"
                ></div>

                <div
                  v-if="item.logo"
                  class="slide-logo absolute parallax-item"
                  data-depth="1.8"
                >
                  <img :src="item.logo" />
                </div>

                <div
                  v-if="item.icon"
                  class="
                    slide-logo slide-icon
                    absolute
                    parallax-item
                    text-right
                  "
                  data-depth="1.8"
                >
                  <i :class="[`ai-${item.icon}`, item.color]"></i>
                </div>
              </div>
              <div class="slide-image">
                <image-box
                  :show="show"
                  class="parallax-item parallax-relative section-imagebox"
                  data-depth="1.2"
                  :type="'modal'"
                  :data="{ thumb: item.thumb, modal: item.modal }"
                ></image-box>
              </div>
              <div class="line line-grey parallax-item" data-depth="0.6"></div>
            </div>
          </vue-glide-slide>
        </vue-glide>
      </div>
      <button
        class="btn-square btn-icon btn-slider btn-slider-prev parallax-item"
        data-depth="0.7"
        @click="advanceSlide(-1)"
      >
        <i class="ai-left"></i>
      </button>
      <button
        class="btn-square btn-icon btn-slider btn-slider-next parallax-item"
        data-depth="0.7"
        @click="advanceSlide(1)"
      >
        <i class="ai-right"></i>
      </button>
    </div>
    <div
      class="
        glide__bullets
        layout-row layout-align-center-center
        parallax-flex parallax-item
      "
      data-depth="0.9"
    >
      <button
        v-for="(item, i) in data.slides"
        :key="i"
        class="glide__bullet btn-bullet"
        :data-glide-dir="`=${i}`"
        @click="activeSlide = i"
        :class="{ active: activeSlide == i }"
      ></button>
    </div>
  </div>
</template>

<script>
import { partComponent, modalFunctions } from "@/mixins/core.js";

import "vue-glide-js/dist/vue-glide.css";

import { Glide, GlideSlide } from "vue-glide-js";

import ImageBox from "@/components/ui/ImageBox.vue";

export default {
  name: "BoxSlider",
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
    ImageBox,
  },
  mixins: [partComponent, modalFunctions],
  props: ["show", "data"],
  data() {
    return {
      showImages: false,
      activeSlide: 0,
      options: {
        gap: 0,
        focusAt: 0,
        startAt: 0,
        animationDuration: 600,
        perView: 1,
        rewind: false,
        keyboard: true,
        dragThreshold: 10,
        classes: {
          slider: "slider",
          carousel: "carousel",
          activeSlide: "active",
        },
      },
    };
  },
  computed: {
    totalSlides() {
      return this.data.slides.length;
    },
    currentSlideClass() {
      let currentSlide = `current-${this.activeSlide}`;

      if (this.totalSlides - 1 == this.activeSlide) {
        currentSlide += " current-last";
      } else if (0 == this.activeSlide) {
        currentSlide += " current-first";
      }

      return currentSlide;
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.showSlider();
      }
    },
  },
  methods: {
    advanceSlide(increment) {
      let goToSlide = this.activeSlide + increment;

      if (goToSlide > this.totalSlides - 1 || goToSlide < 0) {
        return;
      }

      this.activeSlide = goToSlide;
    },
    createTimeline() {
      const tl = new TimelineMax({
        paused: true,
      });

      tl.from(
        ".line-grey",
        0.7,
        {
          width: 0,
          ease: Power2.easeOut,
        },
        0
      )
        .from(
          ".slide-title",
          0.7,
          {
            x: "-50",
            autoAlpha: 0,
            ease: Power2.easeOut,
          },
          0
        )
        .from(
          ".slide-logo",
          0.5,
          {
            x: "+=20",
            autoAlpha: 0,
            ease: Power1.easeOut,
          },
          0.5
        )
        .from(
          ".line-blue",
          0.7,
          {
            width: 0,
            ease: Power2.easeOut,
          },
          0
        )
        .from(
          [".slide-text", ".readmore"],
          0.6,
          {
            autoAlpha: 0,
            y: "+=20",
            ease: Power1.easeOut,
          },
          0.2
        )
        .staggerFrom(
          ".btn-bullet",
          0.5,
          {
            autoAlpha: 0,
            y: "+=10",
            ease: Power2.easeInOut,
          },
          0.05,
          0
        )
        .from(
          ".btn-slider-prev",
          0.5,
          {
            autoAlpha: 0,
            x: "+=20",
            ease: Power1.easeOut,
          },
          0.5
        )
        .from(
          ".btn-slider-next",
          0.5,
          {
            autoAlpha: 0,
            x: "-=20",
            ease: Power1.easeOut,
          },
          0.5
        )

        .call(this.showTimelineImages, null, this, 0.2);

      this.timeline = tl;
    },
    showTimelineImages() {
      this.showImages = !this.showImages;
    },
    sliderMounted() {
      console.log("slide mounted");
    },
    showSlider() {},
  },
  mounted() {},
  beforeDestroy() {},
};
</script>

<style scoped>
.box-slider {
  width: 52.5em;
  overflow: hidden;
  position: relative;
  font-size: 1rem;
}

.slide-content {
  margin: 0;
  padding: 0 1.5em 0 3em;
  font-size: 1em;
  position: relative;
  cursor: grab;
  min-height: 33.563em;
}
.slide-text-wrap {
  padding-bottom: 7.5em;
}
.slide-text {
  height: 9em;
  overflow: hidden;
  position: relative;
  z-index: 2;
  cursor: default;
  margin: 0;
  max-width: 20em;
}
.slide-title {
  font-size: 2.7em;
  margin: 1.3em 0 1.26em;
  height: 3em;
  line-height: 1.2;
  max-width: 100%;
  color: #444;
}
.slide-title span {
  font-size: 0.7em;
  color: #666;
}
.content-slider .line {
  top: 13.25em !important;
}
.box-slider .line-grey {
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 20%,
    rgba(0, 0, 0, 0.06) 100%
  );
}
.slide-logo {
  left: auto !important;
  right: 0 !important;
  top: auto !important;
  bottom: 0 !important;
  opacity: 0.8;
}
.slide-icon {
  bottom: 2em !important;
  opacity: 1;
}
.slide-icon i {
  font-size: 3.9em;
}
.slide-icon i.ai-rhino {
  font-size: 4.5em;
}
.slide-logo img {
  height: auto;
  display: block;
  max-width: 6.875em;
}

/*=============================================
=                  MEDIA QUERIES             =
=============================================*/

@media screen and (max-width: 991px) {
  /deep/ .box-slider .info-box-wrap {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 767px) {
  /deep/ .box-slider .info-box-wrap {
    font-size: 0.8rem;
  }
  .timeline-slider-wrap {
    width: 100%;
  }
  .box-slider {
    width: 100%;
  }
  .box-slider .slide-title {
    font-size: 2em;
    margin: 1em 0 1.26em;
  }
  .slide-logo img {
    max-width: 4em;
  }
  .slide-logo {
    bottom: -5.5em !important;
  }
  .slide-text-wrap {
    padding-bottom: 0;
  }
  .content-slider .line {
    top: 9.3em !important;
  }
  .slide-content {
    min-height: 27.5em;
  }
  .btn-slider {
    top: 36% !important;
  }
}

@media screen and (max-width: 639px) {
  .box-slider {
    font-size: 13px;
  }
  .box-slider .slide-text {
    font-size: 12px;
  }
  /deep/ .box-slider .info-box-wrap {
    font-size: 0.7rem;
  }
  .slide-content {
    min-height: 25em;
  }
  .btn-slider {
    top: 38.2% !important;
  }
}

@media screen and (max-width: 479px) {
  /deep/ .box-slider .info-box-wrap {
    font-size: 8px;
  }
  .box-slider .slide-text {
    font-size: 11px;
  }
  .box-slider {
    font-size: 12px;
  }
  .content-slider .line.line-blue {
    width: 6.7em;
  }
  .slide-content {
    min-height: 24.1em;
  }
  .timeline-slider-wrap {
    padding-right: 0;
  }
  .btn-slider {
    top: 40.5% !important;
  }
  .slide-logo {
    bottom: -4.3em !important;
  }
}

@media screen and (max-width: 420px) {
  .box-slider .slide-text-wrap {
    margin-right: 0.2em;
  }
  .box-slider .slide-text {
    font-size: 10px;
  }
  .slide-content {
    padding-left: 10px;
  }
  /deep/ .box-slider .info-box-wrap {
    font-size: 8px;
    margin-top: 0.5em;
  }
  .slide-content {
    min-height: 23.1em;
  }
  .box-slider .slide-title {
    font-size: 1.7em;
    margin: 1.2em 0 2.7em;
  }
  .btn-slider {
    top: 40.5% !important;
  }
}

@media screen and (max-width: 390px) {
  .slide-content {
    padding-left: 4px;
  }
}
</style>
